import { Grid, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import {
  selectAccountEmail,
  selectMuid,
} from '../../../redux/authentication/AuthenticationSlice'
import { useAppSelector } from '../../../redux/storeExports'
import { fonts } from '../../../theme/theme'
import { MpSecondaryButton } from '../atom/buttons/MpSecondaryButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import { selectProfile } from '@meprism/shared/src/redux/profile/ProfileSlice'

const MpFooter = () => {
  const accountEmail = useAppSelector(selectAccountEmail)
  const muid = useAppSelector(selectMuid)
  const profile = useAppSelector(selectProfile)

  useEffect(() => {
    if (profile.names.first.length > 0 && profile.names.last.length > 0) {
      //@ts-ignore
      window.intercomSettings = {
        app_id: 'p9mftca9',
        custom_launcher_selector: '#intercom-launcher',
        email: accountEmail,
        user_id: muid,
        name: profile.names.first[0] + ' ' + profile.names.last[0],
      }
    } else {
      //@ts-ignore
      window.intercomSettings = {
        app_id: 'p9mftca9',
        custom_launcher_selector: '#intercom-launcher',
        email: accountEmail,
        user_id: muid,
      }
    }
    //@ts-ignore
    window.Intercom('update', window.intercomSettings)
  }, [accountEmail, muid, profile.names.first, profile.names.last])
  const textStyle = {
    ...fonts.Jakarta.Regular,
    fontSize: '14px',
  }
  const theme = useTheme()
  const isWide = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid
      container
      justifyContent={'space-between'}
      rowSpacing={2}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 'auto',
        justifySelf: 'flex-end',
        maxWidth: 'xl',
        mt: 'auto',
        mx: 'auto',
        borderTop: '1px solid #EDEDED',
        py: 6,
      }}>
      <Grid item xs={12} sm={6}>
        <Stack
          direction={'row'}
          sx={{ alignItems: 'center', mx: 5 }}
          spacing={2}
          justifyContent={isWide ? 'flex-start' : 'space-between'}
         >
          <Link
            href={'https://meprism.com/terms'}
            sx={{ ...textStyle, color: 'primary' }}>
           Terms & Conditions
          </Link>
          <Link
            href={'https://meprism.com/terms'}
            sx={{ ...textStyle, color: 'primary' }}>
            Privacy Policy
          </Link>
          <Link
            href={'https://meprism.com/privacy-policy'}
            sx={{ ...textStyle, color: 'primary' }}>
            Contact Us
          </Link>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack
          direction={'row'}
          spacing={{ sm: 5, xs: 0 }}
          sx={{ mx: 5 }}
          justifyContent={isWide ? 'flex-end' : 'space-between'}>
          <MpSecondaryButton variant={'outlined'} id={'intercom-launcher'}>
            Customer Support
          </MpSecondaryButton>
          <MpSecondaryButton
            variant={'outlined'}
            href={
              'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/8?email=' +
              accountEmail
            }>
            Leave Feedback
          </MpSecondaryButton>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default MpFooter
