import React, {
  PropsWithChildren,
  Suspense,
  useCallback,
  useState,
} from 'react'
import {
  Box,
  Button,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  Await,
  defer,
  Link,
  LoaderFunction,
  Navigate,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import {
  GlobalAuthContextRef,
  useAuthContext,
} from '@meprism/shared/src/context/AuthContext'
import { signInWithEmail } from '../../services/AuthenticationService'
import { AnalyticsService } from '../../services/AnalyticsService'
import {
  fetchCompanyFederation,
  resendInvite,
} from '@meprism/shared/src/redux/product/ProductSlice'
import { store } from '../../redux/store'
import { AuthLoader } from '../gateways/AuthLoader'
import { LoadingFallback } from '../shared/atom/LoadingFallback'
import PasswordField from '../shared/atom/PasswordField'
import { fonts, MpReTheme, theme } from '../../theme/OnboardingTheme'
import { AuthFooter } from '../layouts/SignInLayout'
import MpTheme from '@meprism/shared/src/config/MpTheme'
import { AuthenticationService } from '@meprism/shared/src/services/AuthenticationService'
import { Logger } from '@meprism/app-utils'
import Loader from '@meprism/shared/src/components/atoms/Loader'

export const loginWithEmailLoader: LoaderFunction = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams
  const token = searchParams.get('token')
  if (token) {
    try {
      const jsonString = atob(token)
      const payload = JSON.parse(jsonString)
      const email = payload?.email
      const password = payload.password
      const expiryTime = payload.expiry_time
      const currentTime = new Date().toISOString().split('T')[0]
      if (currentTime > expiryTime) {
        const response = await store.dispatch(
          resendInvite({ user_email: email }),
        )
        if (!response.payload) {
          Logger.error(
            `Failure in sending the expired invitation Email : ${response}`,
          )
        }
        return defer({ expired: true })
      }

      const user: CognitoUser = await Auth.signIn(email, password)
      GlobalAuthContextRef.current?.setUser(user)
      return defer({ user })
    } catch (error) {
      Logger.error(`Error attempting autosignin: ${error}`)
    }
  }
  return defer({ user: null })
}

const LoginWithEmailRedirector = ({ children }: PropsWithChildren) => {
  const loaderData = useLoaderData() as {
    user: CognitoUser | null
    expired?: boolean
  }
  const authContext = useAuthContext()
  const { user, expired } = loaderData
  if (expired) {
    return <Navigate to="/expired-invitation" />
  }

  if (user) {
    authContext.setUser(user)
    return (
      <Navigate
        to={
          user?.challengeName === 'NEW_PASSWORD_REQUIRED'
            ? '/completePassword'
            : '/'
        }
      />
    )
  }
  return <>{children}</>
}

const LoginWithEmailWrapper = () => {
  const data = useLoaderData() as any

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Await resolve={data.user}>
        <LoginWithEmailRedirector>
          <LoginWithEmailInner />
        </LoginWithEmailRedirector>
      </Await>
    </Suspense>
  )
}

const BUTTON_STYLE = {
  display: 'flex !important',
  justifyContent: 'space-between !important',
  alignItems: 'center !important',
  gap: '10px !important',
  '& .MuiCircularProgress-root': {
    color: '#5856d6 !important',
    width: '20px !important',
    height: '20px !important',
  },
}

export const LoginWithEmailInner = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const textStyle = { ...fonts.Inter.Regular, fontSize: '14px' }
  const authContext = useAuthContext()
  const location = useLocation()
  const from = (location?.state as any)?.from || '/'
  const [queryParams] = useSearchParams()
  const [userEmail, setUserEmail] = useState<string>(
    queryParams.get('email') ?? '',
  )
  const [password, setPassword] = useState<string>(
    queryParams.get('password') ?? '',
  )
  const [errorText, setErrorText] = useState<string>('')
  const [loginStage, setLoginStage] = useState<string>('init')
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const onLogin = async () => {
    try {
      setLoading(true)
      const user: CognitoUser = await signInWithEmail(userEmail, password)
      GlobalAuthContextRef.current?.setUser(user)

      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate('/completePassword', { replace: true })
      } else if (user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
        navigate('/otp', {
          state: {
            actionType: 'SIGNIN_SOFTWARE_TOKEN_MFA',
            username: userEmail,
            password,
            from,
            destination: 'your authenticator app',
          },
        })
      } else {
        navigate(from)
      }
    } catch (error) {
      handleLoginError(error)
      setLoading(false)
    }
  }

  const handleLoginError = (error: any) => {
    if (error instanceof Error) {
      switch (error?.name) {
        case 'UserNotFoundException':
        case 'NotAuthorizedException':
          setErrorText('The userEmail or password entered is incorrect.')
          break
        case 'UserNotConfirmedException':
          setErrorText(
            'Your userEmail address has not yet been verified. Check your userEmail for a verification code.',
          )
          navigate('/otp', {
            state: {
              actionType: 'SIGNUP_EMAIL',
              username: userEmail,
              password,
              from,
            },
          })
          break
        case 'LimitExceededException':
          setErrorText(
            'Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.',
          )
          AnalyticsService.error('CognitoLimit', error)
          break
        default:
          AnalyticsService.error(`Unhandled Authorization error: `, error)
          setErrorText(
            'An error occurred processing your login request. Please try again later.',
          )
          break
      }
    } else {
      AnalyticsService.error(`Unhandled Authorization error: `, error)
    }
  }

  const handleContinue = useCallback(async () => {
    setLoading(true)

    const response: any = await store.dispatch(
      fetchCompanyFederation({ user_email: userEmail }),
    )

    const incomingLoginStage = response?.payload?.includes('okta')
      ? 'federated'
      : 'default'
    setLoginStage(incomingLoginStage)

    if (incomingLoginStage === 'federated') {
      await AuthenticationService.signInWithFederation(
        '/dashboard',
        authContext.setUser,
        response.payload,
      )
    } else {
      setLoading(false)
    }
  }, [userEmail, authContext.setUser, setLoginStage])

  return (
    <ThemeProvider theme={theme}>
      <AuthLoader>
        <Stack
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            mx: 'auto',
            width: '100%',
          }}
          spacing={5}>
          <TextField
            fullWidth
            value={userEmail}
            onChange={(event) => setUserEmail(event.target.value)}
            color={'primary'}
            variant={'outlined'}
            placeholder="Email"
            autoComplete="email"
            InputLabelProps={{ shrink: true }}
          />

          {loginStage !== 'default' && (
            <Box>
              <Button
                variant={'contained'}
                fullWidth
                disabled={userEmail === '' || loading}
                onClick={handleContinue}>
                <Box sx={BUTTON_STYLE}>
                  Continue {<Loader loading={loading} />}
                </Box>
              </Button>
            </Box>
          )}

          {loginStage === 'default' && (
            <>
              <PasswordField
                placeholder="Password"
                fullWidth
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                variant={'outlined'}
                InputLabelProps={{ shrink: true }}
              />
              <Box>
                <Typography color={'error'}>{errorText}</Typography>
              </Box>
              <Link
                to={'/resetPassword'}
                state={{ from }}
                style={{
                  textAlign: 'center',
                  ...textStyle,
                  textDecoration: 'underline',
                  color: '#6F8EF5',
                }}>
                Forgot Password?
              </Link>
              <Box>
                <Button
                  variant={'contained'}
                  fullWidth
                  disabled={userEmail === '' || password === '' || loading}
                  onClick={onLogin}>
                  <Box sx={BUTTON_STYLE}>
                    SignIn {<Loader loading={loading} />}
                  </Box>
                </Button>
              </Box>
              {!isMobile && (
                <Box
                  sx={{
                    maxWidth: MpTheme.layouts.widths.sm,
                    width: '100%',
                    position: 'absolute',
                    bottom: !isMobile ? '30px' : '80px',
                  }}>
                  <Stack
                    direction={'row'}
                    sx={{ alignItems: 'center', mt: 15 }}
                    spacing={!isMobile ? 15 : 10}
                    justifyContent={'space-between'}>
                    <Link
                      to={'https://meprism.com/terms-conditions'}
                      style={{
                        ...textStyle,
                        color: MpReTheme.colors.secondary.default,
                      }}>
                      Terms & Conditions
                    </Link>
                    <Link
                      to={'https://meprism.com/privacy-policy'}
                      style={{
                        ...textStyle,
                        color: MpReTheme.colors.secondary.default,
                      }}>
                      Privacy Policy
                    </Link>
                    <Link
                      to={
                        'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'
                      }
                      style={{
                        ...textStyle,
                        color: MpReTheme.colors.secondary.default,
                      }}>
                      Contact Us
                    </Link>
                  </Stack>
                </Box>
              )}
              <AuthFooter />
            </>
          )}
        </Stack>
      </AuthLoader>
    </ThemeProvider>
  )
}

export default LoginWithEmailWrapper
