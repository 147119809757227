import React from 'react'
import { Stack, Typography, Container } from '@mui/material'
import MpFooter from '../shared/organism/MpFooter'

const ExpiredInvitationLink = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <Stack
        spacing={10}
        sx={{ textAlign: 'center', mt: 20, px: 10, maxWidth: 'md' }}
      >
        <Typography variant="h1">Invitation Link is Expired</Typography>
        <Typography variant="body2">
          Your invitation has expired. Please check your email inbox for an up-to-date invitation.
          If this is your most recent invite, please be on the lookout for a new email in the coming days.
        </Typography>
      </Stack>
      <MpFooter/>
    </Container>
  )
}
export default ExpiredInvitationLink
