import {
  CheckoutSessionRequest,
  CheckoutSessionResponse,
  CodeStatusResponse,
  EntitlementResponse,
  FamilyResponse,
  GrantResponse,
  MpProductResponse,
  OfferResponse,
  PaymentInterval,
  PlatformStatus,
  PortalSessionResponse,
  ProductSlice,
} from './ProductTypes'
import { APIInterface, BaseThunkExtra, createBaseAsyncThunk } from '../base'
import {
  AuthenticationActions,
  handleEntitlementChange,
} from '../authentication/authenticationSlice'
import { Logger } from '@meprism/app-utils'
import { AuthenticationState } from '../authentication/authenticationTypes'
import { PeopleFinderBaseState } from '../peopleFinder/PeopleFinderSlice'
import {
  ActionReducerMapBuilder,
  AnyAction,
  createSelector,
  createSlice,
  SliceCaseReducers,
  ThunkAction,
  ThunkDispatch,
  TypedStartListening,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit'

type BaseProductSlice = {
  product: ProductSlice
  authentication: AuthenticationState
}

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  unknown,
  BaseThunkExtra,
  AnyAction
>

export const initialProductState: ProductSlice = {
  iapHubProducts: {},
  iapHubBillingStatus: {
    error: null,
    filteredProductIds: [],
  },
  mpProducts: {},
  subscriptions: [],
  isLoading: false,
  grant: {
    grants: [],
  },
  familyPlan: {
    active: false,
    email: '',
    invite_status: '',
  },
  offer: undefined,
}

export const hasPaymentFailed = (
  platformStatus: PlatformStatus | undefined,
): boolean => {
  if (!platformStatus) {
    return false
  }
  return (
    platformStatus === 'grace_period' ||
    platformStatus === 'retry_period' ||
    platformStatus === 'past_due' ||
    platformStatus === 'unpaid'
  )
}
const getGrants = async (API: APIInterface): Promise<GrantResponse> => {
  return API.get('Subscription', '/v2/grants', {})
}
const getFamilyStatus = async (API: APIInterface): Promise<FamilyResponse> => {
  return API.get('Subscription', '/family', {})
}

const postFamilyEmail = async (
  API: APIInterface,
  email: string,
): Promise<FamilyResponse> => {
  return API.post('Subscription', '/family', { body: email })
}

const getEntitlements = async (
  API: APIInterface,
): Promise<EntitlementResponse> => {
  return API.get('Subscription', '/claims', {})
}

const getMpProducts = async (API: APIInterface): Promise<MpProductResponse> => {
  return API.get('Subscription', '/stripe/product', {})
}

const postInvitationAccept = async (API: APIInterface): Promise<any> => {
  return API.post('Subscription', '/v2/invite/accept', {})
}

const createCheckoutSession = async (
  sessionRequest: CheckoutSessionRequest,
  API: APIInterface,
): Promise<CheckoutSessionResponse> => {
  return API.post('Subscription', '/stripe/checkout-session', {
    body: sessionRequest,
  })
}

const postPortalSession = async (
  API: APIInterface,
): Promise<PortalSessionResponse> => {
  return API.post('Subscription', '/stripe/portal-session', {})
}

type CorporateCodePost = {
  muid: string
  code: string
}
const putCorporateCode = async (
  { muid, code }: CorporateCodePost,
  API: APIInterface,
): Promise<EntitlementResponse> => {
  return API.put('Subscription', `/codes/${muid}/${code}/redeem`, {})
}

const getCodeStatus = async (
  code: string,
  API: APIInterface,
): Promise<CodeStatusResponse> => {
  return API.get('Subscription', `/codes/validate/${code}`, {})
}

const getOfferById = async (
  offerId: string,
  API: APIInterface,
): Promise<OfferResponse> => {
  return API.get('Subscription', `/offer/${offerId}`, {})
}

export const fetchOffer = createBaseAsyncThunk(
  'fetchOffer',
  async (offerId: string, { extra }) => {
    try {
      // return {
      //   description:
      //     'You have been granted 50% off of mePrism. Click here to subscribe',
      //   code: '1234',
      //   code_type: 'stripe' as const,
      // }
      return await getOfferById(offerId, extra.API)
    } catch (error) {
      if (error?.response?.status === 404) {
        return undefined
      }
      Logger.error(`Error fetching ${offerId} ${error}`)
    }
  },
)

export const fetchFamilyStatus = createBaseAsyncThunk(
  'fetchFamilyStatus',
  async (_, { extra }) => {
    try {
      return await getFamilyStatus(extra.API)
    } catch (error) {
      Logger.info('No family status')
      if (error?.response?.status === 404) {
        return {
          active: false,
          email: '',
          invite_status: '',
        } as FamilyResponse
      } else {
        Logger.error(
          `Error fetching family status: ${error?.code} ${error?.message}`,
        )
        throw error
      }
    }
  },
)

export const postFamilyStatus = createBaseAsyncThunk(
  'postFamilyStatus',
  async (payload: string, { extra }) => {
    try {
      const res = await postFamilyEmail(extra.API, payload)
      extra.Toast.show({
        type: 'success',
        text1: 'Successfully added a Plus One!',
      })
      return res
    } catch (error) {
      Logger.error(
        `Error posting family status: ${error?.code} ${error?.message}`,
      )
      extra.Toast.show({
        type: 'error',
        text1: 'There was a problem adding a plus one.',
      })
      throw error.response?.data || error
    }
  },
)

export const fetchGrants = createBaseAsyncThunk(
  'fetchGrants',
  async (_, { extra }) => {
    try {
      return await getGrants(extra.API)
    } catch (error) {
      Logger.error(`Error fetching grants: ${error?.code} ${error?.message}`)
      throw error
    }
  },
)

export const fetchMpProducts = createBaseAsyncThunk(
  'fetchMpProducts',
  async (_, { extra }) => {
    try {
      return await getMpProducts(extra.API)
    } catch (error) {
      Logger.error(`Error getting mePrism products: ${error}`)
      throw error
    }
  },
)

export const fetchCompanyFederation = createBaseAsyncThunk(
  'fetchCompanyFederation',
  async (params: { user_email: string }, { extra }) => {
    try {
      const requestParams = {
        headers: { 'Content-Type': 'application/json' },
        body: {
          user_email: params.user_email,
        },
        credentials: 'omit',
        authMode: 'NONE',
      }

      return extra.API.post(
        'SubscriptionUnauthenticated',
        '/check_authentication_type',
        requestParams,
      )
    } catch (error) {
      Logger.error(`Error getting company saml status: ${error}`)
    }
  },
)

export const resendInvite = createBaseAsyncThunk(
  'resendInvite',
  async (params: { user_email: string }, { extra }) => {
    try {
      const requestParams = {
        headers: { 'Content-Type': 'application/json' },
        body: {
          user_email: params.user_email,
        },
      }

      return extra.API.post(
        'SubscriptionUnauthenticated',
        '/resend_invite',
        requestParams,
      )
    } catch (error) {
      Logger.error(`Error resending the email: ${error}`)
    }
  },
)

export const purchaseMpSubscription = createBaseAsyncThunk(
  'purchaseMpSubscription',
  async (req: CheckoutSessionRequest, { extra }) => {
    try {
      Logger.info(`Attempting purchase of ${req.sku}`)
      const session = await createCheckoutSession(req, extra.API)
      // AnalyticsManager.trackTypedEvent({
      //   event: 'Subscription Purchase',
      //   sku,
      // })
      // Additionally, we suspect that if users buy something, their entitlements should change
      // that's handled by the listener defined below.
      return session
    } catch (error) {
      Logger.error(`Error creating checkout session: ${error}`)
      throw error
    }
  },
)

export const createPortalSession = createBaseAsyncThunk(
  'createPortalSession',
  async (_, { extra }) => {
    try {
      return await postPortalSession(extra.API)
    } catch (error) {
      Logger.error(`Error creating portal session: ${error}`)
      extra.Toast.show({
        type: 'error',
        text1: 'We were unable to reach your payment portal',
      })
      throw error
    }
  },
)

export const checkForEntitlementUpdates = createBaseAsyncThunk(
  'checkForEntitlementUpdates',
  async (_, { getState, dispatch, extra }) => {
    // this app state listener is applied globally, so don't worry about it if no user
    if (!(getState() as BaseProductSlice)?.authentication?.muid) {
      Logger.debug(
        'No logged in user - not attempting to check entitlement updates',
      )
      return
    }
    let entitlements: string[]
    try {
      const response = await getEntitlements(extra.API)
      entitlements = response.meprism_claims
    } catch (error) {
      // we'll treat a 404 as no claims
      if (error?.response?.status === 404) {
        Logger.debug('No entitlements found')
        entitlements = []
      } else {
        Logger.error(`Error checking for entitlement updates: ${error}`)
        throw error
      }
    }
    return dispatch(handleIncomingEntitlements(entitlements))
  },
)

const handleIncomingEntitlements =
  (entitlements: string[]): AppThunk<boolean> =>
  (dispatch, getState) => {
    const state = getState() as PeopleFinderBaseState
    const previousEntitlements = state?.authentication?.entitlements ?? []
    const entitlementSet = new Set(entitlements)

    // we can do a set compare here to see if entitlements changed
    // since we don't have lodash or similar as a direct dep, I'll do it inline
    // @TODO: If we get a util library like lodash, use it instead of inlining the compare
    if (
      entitlementSet.size !== previousEntitlements.length ||
      !previousEntitlements.every((el) => entitlementSet.has(el))
    ) {
      // in this case we have an entitlement change
      // explicitly do NOT update the store based on this new information, the store
      // reflects what's in our token NOW not what SHOULD be in our token
      // the token refresh event should then update the store. Should that fail, the
      // desync persists, and we'll continue to try to fetch a new token.
      Logger.info(
        'Notified of an entitlement change via app state listener - refreshing token',
      )
      handleEntitlementChange()
      return true
    } else {
      Logger.info('No entitlement change')
      return false
    }
  }

type RedeemCodeProps = { code: string }
export const redeemCode = createBaseAsyncThunk(
  'redeemCode',
  async ({ code }: RedeemCodeProps, { dispatch, getState, extra }) => {
    const muid = (getState() as PeopleFinderBaseState).authentication?.muid
    if (!muid) {
      throw Error('No muid for code redemption')
    }

    try {
      const res = await putCorporateCode({ code, muid }, extra.API)
      extra.Toast.show({
        type: 'success',
        text1: 'Your code was successfully redeemed!',
        text2: 'Your account will be credited with a mePrism subscription!',
      })
      return dispatch(handleIncomingEntitlements(res.meprism_claims))
    } catch (error) {
      const status = error?.response?.status
      if (status === 410) {
        extra.Toast.show({
          type: 'error',
          text1: 'That code is expired or has already been fully used',
          text2:
            'Please contact your company administrator to get a new code, or contact support',
          onPress: () => extra.handleSupport(),
        })
        throw error
      } else if (status === 404 || status === 403) {
        // this is the case where the code does not exist...
        // handle 403 here because the relevant thing is the code was not valid FOR THAT MUID
        // can change this as we get clarification about more
        extra.Toast.show({
          type: 'error',
          text1: 'We could not find that code',
          text2:
            'Please contact your company administrator to get a new code, or contact support',
          onPress: () => extra.handleSupport(),
        })
        // @TODO: Handle OS native codes here without delivering opaque error
        throw error
      }

      Logger.error(`Unknown error redeeming code: ${error}`)
      extra.Toast.show({
        type: 'error',
        text1: 'An unknown error occurred',
        text2:
          'Please contact your company administrator to get a new code, or contact support',
        onPress: () => extra.handleSupport(),
      })
      throw error
    }
  },
)

export const fetchCodeStatus = createBaseAsyncThunk(
  'fetchCodeStatus',
  async ({ code }: RedeemCodeProps, { getState, extra }) => {
    const muid = (getState() as PeopleFinderBaseState).authentication?.muid
    if (!muid) {
      throw Error('No muid for code redemption')
    }
    try {
      const res = await getCodeStatus(code, extra.API)
      return res
    } catch (error: any) {
      extra.Toast.show({
        type: 'error',
        text1: 'An unknown error occurred',
        text2:
          'Please contact your company administrator to get a new code, or contact support',
        onPress: () => extra.handleSupport(),
      })
    }
  },
)

export type CreateProductSliceProps<
  CR extends SliceCaseReducers<ProductSlice>,
> = {
  additionalReducers: (builder: ActionReducerMapBuilder<ProductSlice>) => void
  reducers: ValidateSliceCaseReducers<ProductSlice, CR>
}

export const setUpProductAuthListener = (
  listen: TypedStartListening<
    BaseProductSlice,
    ThunkDispatch<BaseProductSlice, BaseThunkExtra, AnyAction>,
    BaseThunkExtra
  >,
) => {
  listen({
    actionCreator: AuthenticationActions.emitAmplifyEvent,
    effect: async (action, listenerApi) => {
      if (action.payload !== 'signIn') {
        return
      }
      try {
        const grant = await listenerApi.dispatch(fetchGrants()).unwrap()
        const corporateGrant = grant.grants.find(
          (g) => g.grant_source === 'corporate_grant',
        )
        const familyGrant = grant.grants.find(
          (g) => g.grant_source === 'family_grant',
        )
        if (corporateGrant) {
          // if we have a corporate grant, tell em we signed up
          try {
            await postInvitationAccept(listenerApi.extra.API)
          } catch (error) {
            Logger.error(`Error notifying of corporate signup: ${error}`)
            throw error
          }
        }
        if (familyGrant) {
          // if we have a family grant, tell em we signed up
          try {
            await postInvitationAccept(listenerApi.extra.API)
          } catch (error) {
            Logger.error(`Error notifying of family signup: ${error}`)
            throw error
          }
        }
      } catch (error) {
        // if we can't get em, nothing to do
        return
      }
    },
  })
}

export const createProductSlice = <
  CaseReducers extends SliceCaseReducers<ProductSlice>,
>({
  additionalReducers = () => {},
  reducers,
}: CreateProductSliceProps<CaseReducers>) =>
  createSlice({
    name: 'products',
    initialState: initialProductState,
    reducers,
    extraReducers: (builder) => {
      builder.addCase(fetchMpProducts.fulfilled, (state, action) => {
        state.mpProducts = Object.fromEntries(
          action.payload.map((product) => [product.sku, product]),
        )
      })
      builder.addCase(fetchGrants.fulfilled, (state, action) => {
        const { payload } = action
        state.grant.grants = payload.grants ?? []
        state.grant.latest_expiration = payload.latest_expiration
      })
      builder.addCase(redeemCode.fulfilled, (state) => {
        state.isLoading = false
      })
      builder.addCase(redeemCode.rejected, (state) => {
        state.isLoading = false
      })
      builder.addCase(redeemCode.pending, (state) => {
        state.isLoading = true
      })
      builder.addCase(fetchCodeStatus.pending, (state) => {
        state.isLoading = true
      })
      builder.addCase(fetchCodeStatus.fulfilled, (state) => {
        state.isLoading = false
      })
      builder.addCase(fetchCodeStatus.rejected, (state) => {
        state.isLoading = false
      })
      builder.addCase(fetchFamilyStatus.pending, (state) => {
        state.familyPlan.error = undefined
      })
      builder.addCase(fetchFamilyStatus.fulfilled, (state, action) => {
        const { payload } = action
        state.familyPlan = payload
      })
      builder.addCase(fetchFamilyStatus.rejected, (state, action) => {
        const { error } = action
        state.familyPlan.error = error.message
      })
      builder.addCase(postFamilyStatus.pending, (state) => {
        state.familyPlan.error = undefined
      })
      builder.addCase(postFamilyStatus.rejected, (state, action) => {
        const { error } = action
        state.familyPlan.error = error.message as string
      })
      builder.addCase(postFamilyStatus.fulfilled, (state, action) => {
        const { payload } = action
        state.familyPlan = payload
      })

      builder.addCase(fetchOffer.fulfilled, (state, { payload }) => {
        state.offer = payload
      })

      additionalReducers(builder)
    },
  })

const selectGrant = (state: BaseProductSlice) => state.product.grant
export const selectActiveGrant = createSelector([selectGrant], (grant) => {
  if (
    grant.latest_expiration &&
    new Date(grant.latest_expiration) < new Date()
  ) {
    return undefined
  }
  return grant.grants.find(
    (g) => g.grant_expiration === grant.latest_expiration,
  )
})
export const selectPlatformStatus = createSelector([selectGrant], (grant) => {
  if (
    grant.latest_expiration &&
    new Date(grant.latest_expiration) < new Date()
  ) {
    return undefined
  }
  const latestGrant = grant.grants.find(
    (g) => g.grant_expiration === grant.latest_expiration,
  )
  return latestGrant?.platform_status
})
export const selectPaymentPlatform = createSelector([selectGrant], (grant) => {
  if (
    grant.latest_expiration &&
    new Date(grant.latest_expiration) < new Date()
  ) {
    return undefined
  }
  const latestGrant = grant.grants.find(
    (g) => g.grant_expiration === grant.latest_expiration,
  )
  return latestGrant?.payment_platform
})
export const selectShouldDisplaySubscriptionWidget = createSelector(
  [selectGrant],
  (grant) => {
    if (
      grant.latest_expiration &&
      new Date(grant.latest_expiration) < new Date()
    ) {
      return true
    }
    const latestGrant = grant.grants.find(
      (g) => g.grant_expiration === grant.latest_expiration,
    )
    return !latestGrant?.active
  },
)

export const selectPurchaseableMpProducts = createSelector(
  [(state: BaseProductSlice) => state.product.mpProducts],
  (products) =>
    Object.fromEntries(
      Object.entries(products).filter(
        ([_, value]) =>
          value.sku &&
          (value.sku.includes('pro.t0.p0.month') ||
            value.sku.includes('pro.t0.p0.year') ||
            value.sku.includes('pro.fam.p0.month') ||
            value.sku.includes('pro.fam.p0.year')),
      ),
    ),
)

export const selectProductsByPaymentInterval = createSelector(
  [
    (state: BaseProductSlice) => state.product.mpProducts,
    (state: BaseProductSlice, interval: PaymentInterval) => interval,
  ],
  (products, interval) => {
    let skus: string[] = []
    if (interval === 'monthly') {
      skus = ['pro.t0.p0.month', 'pro.fam.p0.month']
    } else if (interval === 'yearly') {
      skus = ['pro.t0.p0.year', 'pro.fam.p0.year']
    }

    return Object.fromEntries(
      Object.entries(products).filter(
        ([_, value]) =>
          value.sku && skus.some((sku) => value.sku.includes(sku)),
      ),
    )
  },
)

export const selectPurchaseableIaphubProducts = createSelector(
  [(state: BaseProductSlice) => state.product.iapHubProducts],
  (products) =>
    Object.fromEntries(
      Object.entries(products).filter(
        ([_, value]) =>
          value.sku &&
          (value.sku.includes('pro.t0.p0.month') ||
            value.sku.includes('pro.t0.p0.year') ||
            value.sku.includes('pro.fam.p0.month') ||
            value.sku.includes('pro.fam.p0.year')),
      ),
    ),
)

export const selectIaphubProductsByPaymentInterval = createSelector(
  [
    (state: BaseProductSlice) => state.product.iapHubProducts,
    (state: BaseProductSlice, interval: PaymentInterval) => interval,
  ],
  (products, interval) => {
    let skus: string[] = []
    if (interval === 'monthly') {
      skus = ['pro.t0.p0.month', 'pro.fam.p0.month']
    } else if (interval === 'yearly') {
      skus = ['pro.t0.p0.year', 'pro.fam.p0.year']
    }

    return Object.fromEntries(
      Object.entries(products).filter(
        ([_, value]) =>
          value.sku && skus.some((sku) => value.sku.includes(sku)),
      ),
    )
  },
)

export const selectOffer = (state: BaseProductSlice) => state.product.offer

export const selectPendingOffer = createSelector(
  [selectOffer, selectActiveGrant],
  (offer, grant) => {
    if (!offer || grant) {
      // either no offer to show OR user has a grant so forget it
      return undefined
    }
    if (offer?.expiry_date && new Date(offer?.expiry_date) < new Date()) {
      // offer is expired
      return undefined
    }
    return offer
  },
)

export const selectIsMobileSubscriber = (state: BaseProductSlice) =>
  state.product.subscriptions.length > 0
export const selectIsProductSliceLoading = (state: BaseProductSlice) =>
  state.product.isLoading
export const selectFamilyStatus = (state: BaseProductSlice) =>
  state.product.familyPlan
